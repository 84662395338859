import React from 'react';
import {get_query, insert, login, remove, getAuth, signOut} from '../firebase.tsx';
import uhr from '../assets/img/uhr.jpg';
import "../assets/css/login.css";
import { useState, useEffect } from 'react';
import { Trash } from "react-bootstrap-icons";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function Home() {
    const [user, setUser] = useState(null);
    const [entries, setEntries] = useState([]);
    const [Gesamtarbeitszeit, setGesamtarbeitszeit] = useState([0, 0]);
    const [Durchschnittsarbeitszeit, setDurchschnittsarbeitszeit] = useState([0, 0]);
    const [Tagesarbeitszeit, setTagesarbeitszeit] = useState([0, 0]);
    const today = new Date().toISOString().split("T")[0];
    const [date, setDate] = useState(today);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [description, setDescription] = useState("");
    const [calculatedHours, setCalculatedHours] = useState(0);
    const [calculatedMinutes, setCalculatedMinutes] = useState(0);
    const [category, setCategory] = useState('Sonstiges');
    const [showModal, setShowModal] = useState(false);
    const [selectedEntry, setSelectedEntry] = useState({});

    const activities = ['Sonstiges', 'Business Plan', 'Marketing', 'Sales', 'Programmierung', 'Design', 'Meeting', 'Event', 'Bewerbungsgespräch', 'Organisation', "Support", "Dokumentation"];

    const isFormValid = date && startTime !== "" && endTime !== "";

    useEffect(() => {
        const calculateTimeDifference = () => {
            if (startTime && endTime) {
            const [startHours, startMinutes] = startTime.split(":").map(Number);
            const [endHours, endMinutes] = endTime.split(":").map(Number);
            
            let totalMinutes = (endHours * 60 + endMinutes) - (startHours * 60 + startMinutes);
            if (totalMinutes < 0) totalMinutes += 24 * 60;
            
            setCalculatedHours(Math.floor(totalMinutes / 60));
            setCalculatedMinutes(totalMinutes % 60);
            }
        };
        calculateTimeDifference();
    }, [startTime, endTime]);

    useEffect(() => {
        const unsubscribe = getAuth().onAuthStateChanged(user => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        })
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const get_entries = async () => {
            const entries = await get_query('Arbeitszeit', ['Nutzer', '==', user.uid])
            setEntries(entries);
        }
            
        if (user != null){
            get_entries();
        }
    }, [user, date]);

    useEffect(() => {
        if(entries.length > 0){
            let totalHours = 0;
            let totalMinutes = 0;
            setGesamtarbeitszeit([0, 0]);

            entries.forEach((entry) => {
                totalHours += Number(entry.Stunden);
                totalMinutes += Number(entry.Minuten);
            });
            
            totalHours += Math.floor(totalMinutes / 60);
            totalMinutes = totalMinutes % 60;
            
            setGesamtarbeitszeit([totalHours, totalMinutes]);
            const totalMinutesWorked = entries.reduce((acc, entry) => acc + entry.Stunden * 60 + entry.Minuten, 0);
            const uniqueDays = [...new Set(entries.map(entry => entry.Datum))].length;
            const averageMinutesPerDay = uniqueDays > 0 ? Math.floor(totalMinutesWorked / uniqueDays) : 0;
            const averageHoursPerDay = Math.floor(averageMinutesPerDay / 60);
            const averageMinutesRemainingPerDay = averageMinutesPerDay % 60;
            setDurchschnittsarbeitszeit([averageHoursPerDay, averageMinutesRemainingPerDay]);

            const totalMinutesToday = entries
                .filter((entry) => entry.Datum === date)
                .reduce((acc, entry) => acc + entry.Stunden * 60 + entry.Minuten, 0);
            const totalHoursToday = Math.floor(totalMinutesToday / 60);
            const totalMinutesRemainingToday = totalMinutesToday % 60;
            setTagesarbeitszeit([totalHoursToday, totalMinutesRemainingToday]);
        }  
    }, [entries]);

    const login_google = async (e) => {
        e.preventDefault();
        await login();
    };

    const logout = async (e) => {
        await signOut(getAuth());
    }

    const insert_time = async (e) => {
        const data = {
            Nutzer: user.uid,
            Datum: date,
            Stunden: calculatedHours,
            Minuten: calculatedMinutes,
            Tätigkeit: description,
            Kategorie: category, 
            Start: startTime,
            Ende: endTime,
        };
        insert('Arbeitszeit', data);
        setEntries((prevEntries) => [data, ...prevEntries]);
        setDate(today);
        setStartTime("");
        setEndTime("");
        setDescription("");
        setCategory('Sonstiges');
    };

    const handleDeleteClick = (entry) => {
        if (entry) {
            remove("Arbeitszeit", entry.id);
            setEntries((prevEntries) => prevEntries.filter(e => e.id !== entry.id));
        }
        //setSelectedEntry(entry);
        //setShowModal(true);
    };
    
    const confirmDelete = () => {
        if (selectedEntry) {
          remove("Arbeitszeit", selectedEntry.id);
          setEntries((prevEntries) => prevEntries.filter(e => e.id !== selectedEntry.id));
        }
        setShowModal(false);
    };

    const handleClose = () => setShowModal(false)

    return(
        <div className="position-relative vh-100 overflow-auto">
        {(user === null) ? (
            <div className="login">
                <img src={uhr} alt="background" className="position-absolute top-0 start-0 w-100 h-100 object-fit-cover z-0"/>
                <div className='login__container z-1'>
                    <h1 className='mt-5 fs-1'>Startup<br/>Stempeluhr</h1>
                    <button className="login__btn login__google" onClick={login_google}>
                        Sign in with Google
                    </button>
                </div>
            </div>
        ) : (
            <div className="login">
                <img src={uhr} alt="background" className="position-absolute top-0 start-0 w-100 h-100 object-fit-cover z-0"/>
                <div className='login__container z-1 position-relative vh-100 overflow-auto p-5'>
                    <h1 className='mt-5 fs-1'>Hallo<br/>{user.displayName}</h1>
                    <button className="login__btn login__google" onClick={logout}>
                        Abmelden
                    </button>
                    <div className='d-flex start gap-3 flex-column flex-md-row'>
                        <div className="container mt-5 p-4 bg-light shadow rounded">
                            <h2 className="mb-4">Zeiterfassung</h2>
                            
                            <label className="form-label">Datum:</label>
                            <input
                                type="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                className="form-control mb-3"
                            />

                            <label className="form-label">Startzeit:</label>
                            <input
                                type="time"
                                value={startTime}
                                onChange={(e) => {
                                setStartTime(e.target.value);
                                }}
                                className="form-control mb-3"
                            />

                            <label className="form-label">Endzeit:</label>
                            <input
                                type="time"
                                value={endTime}
                                onChange={(e) => {
                                setEndTime(e.target.value);
                                }}
                                className="form-control mb-3"
                            />

                            <p><strong>Berechnete Arbeitszeit:</strong> {calculatedHours} Stunden, {calculatedMinutes} Minuten</p>

                            <label className="form-label">Kategorie wählen:</label>
                            <select
                                className="form-select mb-3"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                            >
                                {activities.map((activity, index) => (
                                <option key={index} value={activity}>{activity}</option>
                                ))}
                            </select>

                            <label className="form-label">Tätigkeit (optional):</label>
                            <textarea
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                className="form-control mb-3"
                                placeholder="Beschreibung der Tätigkeit..."
                            ></textarea>

                            <button
                                disabled={!isFormValid}
                                className={`btn btn-primary w-100 ${!isFormValid ? "disabled" : ""}`}
                                onClick={insert_time}
                            >
                                Abschicken
                            </button>
                        </div>
                        <div className="container mt-5 p-4 bg-light shadow rounded">
                            <h2 className="mb-4">Statistik</h2>
                            <p className='float-start'><strong>Gesamtarbeitszeit:</strong> {Gesamtarbeitszeit[0]}:{Gesamtarbeitszeit[1]}</p><br/>
                            <p className='float-start'><strong>Durchschnittliche Arbeitszeit pro Tag:</strong> {Durchschnittsarbeitszeit[0]}:{Durchschnittsarbeitszeit[1]}</p><br/>
                            <p className='float-start'><strong>Arbeitszeit heute:</strong> {Tagesarbeitszeit[0]}:{Tagesarbeitszeit[1]}</p>
                        </div>
                        <div className="container mt-5 p-4 bg-light shadow rounded">
                            <h2 className="mb-4">Deine Einträge</h2>
                            {entries.length > 0 ? (
                                <div>
                                {entries.map((entry) => (
                                    <div key={entry.id} className="container mb-3 p-3 border rounded position-relative">
                                    <p className="text-start mb-1"><strong>Datum:</strong> {entry.Datum || "N/A"}</p>
                                    <p className="text-start mb-1"><strong>Zeit:</strong> {entry.Stunden}:{entry.Minuten}</p>
                                    <p className="text-start mb-1"><strong>Kategorie:</strong> {entry.Kategorie || "Keine Kategorie angegeben"}</p>
                                    <p className="text-start mb-1"><strong>Tätigkeit:</strong> {entry.Tätigkeit || "Keine Tätigkeit angegeben"}</p>
                                    <p className="text-start mb-1"><strong>Start:</strong> {entry.Start || "Kein Start angegeben"}</p>
                                    <p className="text-start mb-1"><strong>Ende:</strong> {entry.Ende || "Kein Ende angegeben"}</p>

                                    <Trash
                                        className="position-absolute top-0 end-0 m-2 text-danger cursor-pointer"
                                        size={24}
                                        onClick={() => handleDeleteClick(entry)}
                                    />
                                    </div>
                                ))}
                                </div>
                            ) : ("")}
                        </div>
                    </div>
                    {/* {showModal && ( */}
                    {/* <Modal.Dialog show={showModal} onHide={() => handleClose()} centered>
                        <Modal.Header closeButton>
                        <Modal.Title>Eintrag löschen?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Möchtest du diesen Eintrag wirklich löschen?</Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose()}>Abbrechen</Button>
                        <Button variant="danger" onClick={() => confirmDelete()}>Löschen</Button>
                        </Modal.Footer>
                    </Modal.Dialog> */}
                    {/* )} */}
                </div>
            </div>
        )}
        </div>
    );
};