import React, { useState } from 'react';

const YouTubeDownloader = () => {
  const [url, setUrl] = useState('');
  const [format, setFormat] = useState('mp4');
  const [error, setError] = useState('');

  const handleDownload = () => {
    try {
        console.log(url);
    } catch (error) {
        setError(error);
    }     
  };

  return (
    <div style={styles.container}>
      <h2>YouTube Downloader</h2>

      <label style={styles.label}>Enter YouTube URL:</label>
      <input
        type="text"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="https://www.youtube.com/watch?v=abc123"
        style={styles.input}
      />

      <label style={styles.label}>Select Format:</label>
      <select value={format} onChange={(e) => setFormat(e.target.value)} style={styles.input}>
        <option value="mp4">MP4</option>
        <option value="mp3">MP3</option>
      </select>

      {error && <p style={styles.error}>{error}</p>}

      <button onClick={handleDownload} style={styles.button}>
        Download
      </button>
    </div>
  );
};

// Some basic styles
const styles = {
  container: {
    padding: '20px',
    maxWidth: '400px',
    margin: '0 auto',
    textAlign: 'center',
  },
  label: {
    display: 'block',
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '20px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    marginBottom: '20px',
  },
};

export default YouTubeDownloader;
