import React from 'react';
import YTLogo from '../assets/img/yt.png'

export default function Tools() {
    return(
        <div>
            <h1 className='mt-5 fs-1'>Tools</h1>
            <a href='/youtubedownloader' className='text-decoration-none text-reset d-flex align-items-center justify-content-center'>
                <div className='bg-light border-radius-4' style={{width: '200px'}}>
                    <img src={YTLogo} alt='youtube' width={150}/>
                    <p className='text-decoration-none'>Youtube Downloader</p>
                </div>
            </a>
            
        </div>
    );
};