import { initializeApp } from "firebase/app";
import { getFirestore, query, getDocs, collection, where, addDoc, getDoc, deleteDoc, updateDoc, doc,} from "firebase/firestore";
import { GoogleAuthProvider, getAuth, signInWithPopup, signOut, onAuthStateChanged, browserLocalPersistence, setPersistence,} from "firebase/auth";
export {signOut, onAuthStateChanged, getAuth};

const firebaseConfig = {
  apiKey: "AIzaSyAL2yTKUW3_yFo947GMsOKJUAzgW7nIWgw",
  authDomain: "personal-f94da.firebaseapp.com",
  projectId: "personal-f94da",
  storageBucket: "personal-f94da.appspot.com",
  messagingSenderId: "468959919291",
  appId: "1:468959919291:web:d307f261423e011f527d9e",
  measurementId: "G-JTZT9TFH3C"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

export const login = async () => {
  await setPersistence(getAuth(), browserLocalPersistence);
  const res = await signInWithPopup(getAuth(), googleProvider);
  const user = res.user;
  console.log(user);
  if(user != null){
    return true;
  } else{
    return false;
  }
}

export const insert = async (col: string, data: any) => {
  try {
      console.log(data);
      const docRef = await addDoc(collection(db, col), data);
      console.log("Document written with ID: ", docRef.id);
      return docRef.id;
    } catch (e) {
      console.error("Error adding document: ", e);
    }
}

export const update = async (col: string, docId: string, newData: any) => {
  try {
    const docRef = doc(db, col, docId);
    await updateDoc(docRef, newData);
    console.log("Document updated successfully");
  } catch (e) {
    console.error("Error updating document: ", e);
  }
}

export const remove = async (col: string, docId: string) => {
  try {
    const docRef = doc(db, col, docId);
    await deleteDoc(docRef);
    console.log("Document deleted successfully");
  } catch (e) {
    console.error("Error deleting document: ", e);
  }
}

export const get = async (col: string, docId: string) => {
  try {
    const docRef = doc(db, col, docId);
    
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
    } else {
      console.log("No such document!");
    }
  } catch (e) {
    console.error("Error getting document: ", e);
  }
};

export const get_query = async (col: string, qry: any) => {
  try {
    const querySnapshot = await getDocs(query(collection(db, col), where(qry[0], qry[1], qry[2])));
    if(querySnapshot.empty){
      return [];
    }
    const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    console.log("return", data);
    return data;
  } catch (e) {
    console.error("Error getting documents: ", e);
  }
};
