import './App.css';
import { React } from 'react';
import { Route, Routes } from "react-router-dom";
import Stempeluhr from './pages/Stempeluhr';
import Home from './pages/Home';
import KANU from './pages/KANU';
import Tools from './pages/Tools';
import YoutubeDownloader from './pages/YoutubeDownloader'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="" element={<Home/>} />
        <Route exact path="KANU" element={<KANU/>} />
        <Route exact path="tools" element={<Tools/>} />
        <Route exact path="youtubedownloader" element={<YoutubeDownloader/>} />
        <Route exact path="stempeluhr" element={<Stempeluhr/>} />
      </Routes>
    </div>
  );
}

export default App;
